import * as React from "react";
import "./lifestats.css";

export default () => {
  return (
    <div className="lifestats">
      <h3 className="title">⏺ Live Stats</h3>
      <div className="parent">
        <div className="project-items">
          <div className="child">
            <figure>
              <embed src="https://wakatime.com/share/@55f82fcb-723b-44ea-832a-6414b8046a37/972a4ea2-1b44-4e14-802e-93819e9e5b67.svg"></embed>
            </figure>
          </div>
        </div>
        <div className="project-items">
          <div className="child">
            <figure>
              <embed src="https://wakatime.com/share/@55f82fcb-723b-44ea-832a-6414b8046a37/96c5490e-2d1c-424b-bbf3-726333f3c7a6.svg"></embed>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};
